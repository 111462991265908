.header {
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #131921;
  position: sticky;
  top: 0;
  z-index: 100;
}
.header_logo {
  margin: 0 25px;
}

.header_logo_no_pointer {
  margin: 0 25px;
}
.header_logo:hover {
  cursor: pointer;
}
.main_menu_item {
  font-family: "techhabiles";
  margin: 0 25px;
  color: #fff;
  font-size: 25px;
}
.main_menu_item:hover {
  cursor: pointer;
}
