.side_nav {
  top: 100px;
  bottom: -50px;
  width: 200px;
  height: auto;
  position: absolute;
  background-color: #e49635;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.side_nav_without_items {
  height: 0%;
  width: 0%;
}
.list-group {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.list-item {
  margin: 10px;
  color: white;
  font-size: 22px;
  font-weight: bold;
}
