.footer {
  left: 0px;
  bottom: 0px;
  height: 50px;
  width: 100%;
  position: absolute;
  background-color: rgb(253, 201, 138);
  display: flex;
  align-items: center;
  justify-content: center;
}
