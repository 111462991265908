@font-face {
  font-family: "techhabiles";
  src: local("PlayfairDisplay-VariableFont_wght"),
    url("./fonts/PlayfairDisplay-VariableFont_wght.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: "techhabiles";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
