.main_section {
  top: 100px;
  left: 200px;
  bottom: -5%;
  width: 90%;
  position: absolute;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
}
.main_section_without_sidebar {
  top: 100px;
  left: 0px;
  bottom: -5%;
  width: 100%;
  position: absolute;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
}
